import axios from "axios";
import {
  GET_ORDER_COUNT,
  GET_ONLINE_ORDER,
  ERROR_MASSAGE,
  SUCCESS_MESSAGE,
  SET_ONLINE_TABLE_BOOKING_COUNT,
  SEARCH_DRIVER,
  GET_ONLINE_ORDER_TREEDRIVE,
  All_AVAILABLE_DRIVERS,
  GET_ORDER_COUNT_TREEDRIVE,
  GET_ORDER_COUNT_VENDOR,
  GET_RESREVE_TABLE_COUNT
} from "./types";
import appConfig from "../helpers/appConfig";
import { isServerLogout } from "../utils/isServerLogout";
import { search } from "superagent";
export const getOrderCount = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/orders/ordercount`
    );
    const Count = res.data.data;
    // console.log("inside the getOrderCount----->", Count);
    localStorage.setItem("onlineOrderCount", JSON.stringify(Count));
    dispatch({ type: GET_ORDER_COUNT, payload: Count });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const getOrderCountTreeDrive = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/orders/ordercounttreedrive`
    );
    const Count = res.data.data;
    // console.log("inside the getOrderCountTreeDrive action--->", Count);
    localStorage.setItem("treeDriveOrderCount", JSON.stringify(Count));
    dispatch({ type: GET_ORDER_COUNT_TREEDRIVE, payload: Count });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const getOrderCountVendor = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/vendor/orders/orderCount`
    );
    const Count = res.data.data;
    console.log("inside the getOrderCountVendor API action--->", Count);
    localStorage.setItem("vendorOrderCount", JSON.stringify(Count));
    dispatch({ type: GET_ORDER_COUNT_VENDOR, payload: Count });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const getOnlineTableBookingCount = () => async (dispatch) => {
  try {
    const res = await axios.get(`${appConfig.endpoint}/onlineordercount`);
    localStorage.setItem("onlineTableCount", res.data.data);
    dispatch({ type: SET_ONLINE_TABLE_BOOKING_COUNT, payload: res.data.data });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const getOnlineOrders = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/orders/getOrders`,
      data
    );
    // console.log("res in getOrders---->", res);
    dispatch({ type: GET_ONLINE_ORDER, payload: res.data });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const getOnlineOrdersTreeDrive = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/orders/getOrderstreedrive`,
      data
    );
    console.log("res in getOrders tree drive---->", res);
    dispatch({ type: GET_ONLINE_ORDER_TREEDRIVE, payload: res.data });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const changeOrderStatus = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/orders/onlineinvoicecreation`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "changeOrderStatus" }
    });

    if (
      res.data.status == 200 &&
      res.data.message == "invoice generated and order delivered"
    ) {
      let isTdOrder=false
      dispatch(reduceDeliveryStock(data.id,isTdOrder));
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const changeOrderStatusTreeDrive = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/orders/onlineinvoicecreationtreedrive`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "changeOrderStatusTreeDrive" }
    });

    if (
      res.data.status == 200 &&
      res.data.message == "invoice generated and order delivered"
    ) {
       let isTdOrder=true
      dispatch(reduceDeliveryStock(data.id,isTdOrder));
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const reduceDeliveryStock = (id,isTdOrder) => async (dispatch) => {
  try {
    let data = { id: id,isTdOrder };

    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/board/reduceDeliveredStock`,
      data
    );
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const searchDriver = (id,time) => async (dispatch) => {
  try {
    console.log("inside search driver action----->", searchDriver);
    let data = { orderId: id,timeToDriver:time };
    console.log("----data FOR SEARCH }}}}}}}}}}}}}}}}}}}---------",data)

    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/treeDrive/appdriver/searchdriver`,
      data
    );
    console.log("res inside the action--->", res);
    dispatch({ type: SEARCH_DRIVER, payload: res.data.data });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const allAvailableDrivers = (branchId) => async (dispatch) => {
  try {
    console.log("inside All_AVAILABLE_DRIVERS action----->");

    let formData = {
      branchId: branchId
    }

    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/treeDrive/appdriver/allavailbledrivers`,
      formData
    );
    console.log(" allavailbledrivers the action--->", res);
    dispatch({ type: All_AVAILABLE_DRIVERS, payload: res.data.data });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const getResreveTableCount = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/public/getreservetablecount`,
      {}
    );
    // console.log("my resssssssssssssssss", res.data.data);
    const reserveCountTable = res.data.data;
    const todayCount = res.data.todayCount;
    localStorage.setItem("reserveTableCount", res.data.data);
    localStorage.setItem("todayCount", res.data.todayCount);
    dispatch({ type: GET_RESREVE_TABLE_COUNT, payload: res.data.data });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
